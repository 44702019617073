/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn {
    margin: 0 8px 8px 0;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.row {
    margin-bottom: 10px;
}

html,body { height: 100% }
